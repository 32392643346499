.slider-container-component {
  .slider-body {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    .slider-nav {
      display: flex;
      width: 35px;
      font-size: 35px;
      text-align: center;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .slider-scroller {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      transition: transform 1s ease-in-out;
      .slider-container {
 } }        // display: flex
    .slider-item {
      white-space: normal;
      display: inline-block;
      width: 100%;
      vertical-align: top; } } }
