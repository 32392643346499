.results-map-component {
  .gm-style { // prevents map from showing extra squares outside the map container
    overflow: hidden; }
  .card-top {
    position: relative; }
  // override infowindow close icon
  button.gm-ui-hover-effect {
    display: none!important; }
  .infoWindow-close {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    i {
      position: absolute;
      right: 0;
 } }      // override fullscreen behaviour
  .map-fullscreen-trigger {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ffffff;
    padding: 4px 10px;
    font-size: 20px;
    color: #666; }
  &>.row {
    &> [class^= "col"] {
      height: 100%; } } }
