$height: 300px;
.social-media-embed {
   :global {
    .instagram-media-embed {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: relative;
      height: $height;
      padding: 15px; }
    .instagram-media-embed-cover {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      height: $height;
      width: 100%;
      object-fit: cover; }
    .instagram-media-embed-thumbnail {
      padding: 7px;
      background: rgba(255,255,255, 0.7);
      border-radius: 100%;
      img {
        width: 30px;
        height: 30px; } } } }

