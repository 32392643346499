.elements {
  &.dropdown_v2 {
    .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.isOpen {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom: 0; }
      &::after {
        display: none;
        margin-left: 0; }
       :global {
        .bob-icon {
          width: auto;
          i {
            margin-left: 5px;
            vertical-align: middle;
            height: 100%; } } }
      .label {
        white-space: nowrap; } }
    .dropdown-menu {
      min-width: 100%;
      margin-top: 0;
      border: 0;
      left: 50%;
      transform: translateX(-50%);
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top: 0;
      display: block;
      height: auto;
      max-height: 0;
      opacity: 0;
      transition: max-height 2s;
      padding: 0;
      background: transparent;
      .dropdown-item {
        display: none;
        padding: 0; }
      &.isOpen {
        opacity: 1;
        max-height: 1000px;
        .dropdown-item {
          display: block; } }
      .dropdown-item:focus,
      .dropdown-item:hover {
        background: initial; } } } }
