.elements {
  &.modal-component_v2 {
     :global {
      .modal-dialog {
        margin: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        .modal-content {
          height: 100%;
          background: transparent;
          border: none; }
        .modal-close {
          cursor: pointer; }
        .modal-header {
          justify-content: flex-end;
          position: absolute;
          width: 100%;
          padding: 0;
          z-index: 999; }
        .modal-body {
          padding: 0; }
        .modal-body-container {
          display: flex;
          &>* {
            width: 100%; } } } }
    .modal-fluxio {
      &.isFeed {
        .modal-content {
          img {
            max-height: 400px;
            width: 100%;
            object-fit: cover;
            margin-bottom: 50px; } } } }
     :global {
      @media all and (max-width: 768px) {
        .modal-content {
          .container,
          .container-md,
          .container-sm,
          .modal-body {
            padding: 0; } } } } } }
// SSR only
// Since the modal used in the SSR build mounts
// outside of the DOM tree, the parent class defined in this file
// no longer is the parent class in the SSR build, so we copy the same
// css applied in the web build, and move into the class "modal_v2_ssr",
// so it can be properly applied
.modal_v2_ssr {
   :global {
    .modal-dialog {
      margin: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      .modal-content {
        height: 100%;
        background: transparent;
        border: none; }
      .modal-close {
        cursor: pointer; }
      .modal-header {
        justify-content: flex-end;
        position: absolute;
        width: 100%;
        padding: 0;
        z-index: 999; }
      .modal-body {
        padding: 0; }
      .modal-body-container {
        display: flex;
        &>* {
          width: 100%; } } } }
  .modal-fluxio {
    &.isFeed {
      .modal-content {
        img {
          max-height: 400px;
          width: 100%;
          object-fit: cover;
          margin-bottom: 50px; } } } }
   :global {
    @media all and (max-width: 768px) {
      .modal-content {
        .container,
        .container-md,
        .container-sm,
        .modal-body {
          padding: 0; } } } } }

.modal_ssr_backdrop {
  opacity: 0.7 !important; }
