.gallery {
  &.showcase {
    position: relative;
    // make default item samll when hover on another item
    &:hover {
      .item-default {
        flex-grow: 1 !important;
        .gallery-item-content {
          display: none !important; } } }
    .showcase-item {
      position: relative !important;
      flex: 1;
      flex-grow: 1;
      transition: width .2s ease;
      margin-right: 10px !important;
      transition: flex-grow 0.2s ease;
      &:last-child {
        margin-right: 0 !important; }
      &:hover {
        flex-grow: 7 !important;
        .gallery-item-content {
          display: block !important; } }
      &.item-default {
        flex-grow: 6;
        pointer-events: auto;
        .gallery-item-content {
          display: block; } }
       :global {
        .bob-media {
          position: absolute !important;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100% !important;
          height: 100% !important;
          .bob-image,
          img {
            width: 100% !important;
            height: 100% !important; } } }
      .gallery-item-content {
        z-index: 9;
        display: none; } } } }
