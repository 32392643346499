.tabs-container {
  .horizontal-tabs {
    &.is-vertical {
      display: flex;
      &> .bob-background {
        .nav:first-of-type {
          flex-direction: column;
          margin: 0;
          flex-wrap: nowrap;
          .nav-link {
            display: flex;
            flex-direction: column; }
          .nav-item {
            flex-basis: auto;
            flex-grow: 0;
            min-width: 100%; } } }
      &> .tab-content {
        margin: 0;
        flex-grow: 1; } }
    &.is-vertical_notMobile {
      @media all and (min-width: 769px) {
        display: flex;
        &> .bob-background {
          .nav:first-of-type {
            flex-direction: column !important;
            margin: 0;
            flex-wrap: nowrap;
            .nav-link {
              display: flex;
              flex-direction: column !important; }
            .nav-item {
              flex-basis: auto;
              flex-grow: 0;
              min-width: 100%; } } }
        &> .tab-content {
          margin: 0;
          flex-grow: 1; } } }
    .tab-content {
      margin-top: 0; }
     :global {
      .nav-link {
        &>.bob-text {
          width: 100%; } } } }
  @media all and (max-width: 768px) {
    .nav.nav-pills {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      .nav.nav-pills::-webkit-scrollbar {
        isplay: none; } } } }
