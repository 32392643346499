.navigation {
  &.navbar-component {
    z-index: 9;
    .navbar-menu-toggle {
      display: none; }
    .navbar-menu {
      .navbar-menu-container {
        max-height: 500px;
        transition: all 0.25s ease-in;
        >.row {
          width: 100%; } } }
    .navbar-items {
      width: 100%; }
     :global {
      @media all and (max-width: 768px) {
        .navbar-identity {
          display: flex;
          align-items: center;
          justify-content: space-between; }
        .navbar-menu-toggle {
          display: inline-block;
          font-size: 26px;
          cursor: pointer; }
        .navbar-menu {
          height: auto !important;
          .navbar-menu-container {
            flex-direction: column !important;
            justify-content: flex-start !important;
            padding: 15px 0;
            &>* {
              margin-bottom: 10px; }
            &.hide {
              max-height: 0;
              padding: 0;
              overflow: hidden;
              transition: all 0.15s ease-out; } } } } } } }
