.navigation {
  &.navbar-component_v2 {
    &>\:global(.row) {
      min-height: 100%; }
    .navbar-items,
    .navbar-items-container,
    .navbar-items-background {
          width: 100%; }
    .navbar-identity,
    .navbar-items,
    .navbar-identity-container,
    .navbar-items-container {
      min-height: 100%; } } }

