.cookie-banner {
  background: #fff;
  color: #333;
  padding: 20px;
  position: fixed;
  z-index: 10000;
  bottom: 15px;
  left: 15px;
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px; }
.cookie-actions {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%; }
.cookie-banner-modal-drop {
  background-color: #0000006c;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center; }
.cookie-banner-modal {
  background: #fff;
  padding: 20px;
  border-radius: 3px;
  width: 500px;
  position: relative; }
.cookie-options {
  display: flex;
  flex-direction: column; }
.cookie-option {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px; }
.cookie-checkbox {
  transform: scale(1.5); }
.cookie-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1;
  display: block; }
.cookie-description {
  font-size: 0.9rem;
  margin-bottom: 10px; }
.cookie-policy-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  padding: 20px;
  z-index: 10;
  font-size: 0.9rem;
  height: 100%;
  border-radius: 3px;
  overflow-y: auto; }

@media screen and (max-width: 768px) {
  .cookie-banner {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0; } }
