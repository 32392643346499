$navWidth: 25px;
.carousel-component {
  .slider-body {
    // overflow: hidden
    position: relative;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    display: flex;
    align-items: stretch;
    .slider-nav {
      display: flex;
      min-width: $navWidth;
      font-size: $navWidth;
      text-align: center;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 11; }
    .slider-container {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      flex-wrap: nowrap;
      cursor: pointer;
      &\:global(.no-gutters) {
        &>[class*=col-] {
          padding-right: 0;
          padding-left: 0; } }
      .slider-item {
        display: inline-flex;
        align-items: stretch;
        flex-direction: column; } }
     :global {
      .slider-body .slider-nav.left,
      .slider-body .slider-nav.right,
      .slider-container,
      .slider-item {
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out; } } }
  .slider-nav-bottom {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translatex(-50%);
    z-index: 10;
    &>* {
      z-index: 10; }
     :global(i) {
      cursor: pointer; } } }
