.card-left,
.card-right {
   :global {
    .card {
      .container,
      .container-fluid,
      .row {
        height: 100%;
        min-height: 100%; }
      [class^="col-"] {
        align-items: center;
        display: flex;
        &>* {
          width: 100%; } } } } }

// tablet styles
@media all and (max-width: 992px) and (min-width: 767px) {
  .card-left,
  .card-right {
     :global {
      .card {
        .container-md,
        .container-fluid-md {
          height: 100%;
          min-height: 100%; } } } } }

// mobile styles
@media all and (max-width: 766px) {
  .card-left,
  .card-right {
     :global {
      .card {
        .container-sm,
        .container-fluid-sm {
          height: 100%;
          min-height: 100%; } } } } }
