.elements {
  &.modal-component {
     :global {
      .modal-dialog {
        margin: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        .modal-close {
          cursor: pointer; }
        .modal-container {
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          .container {
            height: 100%; } }
        .modal-header {
          justify-content: flex-end; }
        .modal-body {
          display: flex;
          min-height: calc(100% - 15%);
          // justify-content: center
          flex-direction: column;
          &>* {
            width: 100%; } }
        .modal-content {
          height: 100%;
          background: transparent; }
        .modal-header {
          .close {
            padding: 1rem 1rem;
            margin: -1rem -1rem -1rem auto;
            font-size: 40px; } } } }
    .modal-fluxio {
      &.isFeed {
        .modal-content {
          img {
            max-height: 400px;
            width: 100%;
            object-fit: cover;
            margin-bottom: 50px; } } } }
     :global {
      @media all and (max-width: 768px) {
        .modal-content {
          .container,
          .container-md,
          .container-sm,
          .modal-body {
            padding: 0; } } } } } }
// SSR only
// Since the modal used in the SSR build mounts
// outside of the DOM tree, the parent class defined in this file
// no longer is the parent class in the SSR build, so we copy the same
// css applied in the web build, and move into the class "modal_v1_ssr",
// so it can be properly applied
.modal_v1_ssr {
   :global {
    .modal-dialog {
      margin: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      .modal-close {
        cursor: pointer; }
      .modal-container {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        .container {
          height: 100%; } }
      .modal-header {
        justify-content: flex-end; }
      .modal-body {
        display: flex;
        min-height: calc(100% - 15%);
        // justify-content: center
        flex-direction: column;
        &>* {
          width: 100%; } }
      .modal-content {
        height: 100%;
        background: transparent; }
      .modal-header {
        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
          font-size: 40px; } } } }
   :global {
    @media all and (max-width: 768px) {
      .modal-content {
        .container,
        .container-md,
        .container-sm,
        .modal-body {
          padding: 0; } } } } }

.modal_ssr_backdrop {
  opacity: 0.7 !important; }
