.modal-fluxio {
  &.modal-fluxio-lg {
    .modal-dialog {
      max-width: 900px;
      height: 80%;
      top: 70px !important;
      .modal-content {
        min-height: 100%; } }
    &.modal-fluxio-no-height {
      .modal-dialog {
        height: auto; } } }
  &.modal-fluxio-full {
    .modal-dialog {
      max-width: 98%;
      height: 98%;
      top: 0 !important;
      .modal-content {
        min-height: 100%; } } }
   :global(.modal-header) {
    border: none;
    padding-bottom: 0;
    .modal-title {
      font-size: 26px; } }
   :global(.modal-body) {
    display: flex;
    flex-direction: column; }
   :global(.modal-footer) {
    border: none;
    display: flex;
    .btn {
      width: 100%; } }
   :global(img) {
    max-width: 100%; }
   :global(.list-group) {
    overflow: auto; }
  //For the modals only used inside the Experience Manager
  &.modal-fluxio-em {
    z-index: 1000001;
    overflow: hidden auto; }
  &.content-library-modal {
    .modal-dialog {
      width: 35%;
      min-width: 700px;
      max-width: none; } }
  &.content-library-modal-two-sides {
    .modal-dialog {
      width: 50%;
      min-width: 1090px;
      max-width: none; } }
  .ace_editor {
    flex: 1;
    height: initial !important;
    min-height: 400px; } }

//fix for safari 13
.safari-old-version {
  .modal-dialog {
    &.modal-lg {
      height: auto !important; } }
  .container {
    flex-grow: 1; } }
