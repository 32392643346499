.card-deck {
  @media screen and (min-width: 576px) {
    // simulate card-deck behaviour when there's cols as direct children
     :global(.col),
    &>*[class^='col-'] {
      display: flex;
      height: 100%; }
    .card {
      margin: 0; } } }
// no guts if container has no guts
// .container-fluid.p-0
//   .card-deck
//     margin: 0
