.simple-form-component {
   :global {
    .form-group {
      position: relative; }
    .simple-form-error {
      color: red;
      position: absolute;
      bottom: -20px;
      font-size: 14px; }
    .submit-feedback {
      margin-left: 20px;
      .submit-error {
        color: red; }
      .submit-success {
        color: green; } }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    select::-ms-expand {
      display: none; }
    .select-container {
      position: relative; }
    .select-container:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      margin: auto;
      pointer-events: none; }
    .select-container:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid black;
      opacity: 0.5; } } }
