.social-card-share {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: none;
    padding-bottom: 0;
    marign-bottom: 0;
     :global(.bob-image) {
      width: 40px;
      height: 40px;
      object-fit: cover; } }
  .card {
    .card {
      margin-top: 20px; } } }
