.navigation {
  &.menu-component_v2 {
    .main-menu-toggle {
      display: none; }
    .main-menu {
      .main-menu-container {
        flex-direction: row !important;
        justify-content: flex-start !important;
        max-height: 1000px;
        transition: all 0.25s ease-in;
        >.row {
          width: 100%; }
        .main-menu-close {
          display: none; } }
      .main-menu-item {
        width: auto; } }
     :global {
      @media all and (max-width: 768px) {
        .main-menu-toggle {
          display: block;
          font-size: 26px;
          cursor: pointer; }
        .main-menu {
          height: auto !important;
          .main-menu-container {
            position: fixed !important;
            height: 100% !important;
            z-index: 9;
            width: 100%;
            left: 0;
            top: 0;
            &>div {
              flex-direction: column !important;
              &>* {
                height: auto; } }
            &.hide {
              max-height: 0;
              padding: 0;
              overflow: hidden;
              transition: all 0.15s ease-out; }
            .main-menu-close {
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer; } } } } } } }
