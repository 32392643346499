.elements {
  &.modal-component_v4 {
     :global {
      .modal-dialog {
        margin: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        .modal-content {
          height: 100%;
          background: transparent;
          border: none; }
        .modal-close {
          cursor: pointer; }
        .modal-header {
          justify-content: flex-end;
          position: absolute;
          width: 100%;
          padding: 0;
          z-index: 999; }
        .modal-body {
          padding: 0; }
        .modal-body-container {
          display: flex;
          height: auto !important;
          &>* {
            width: 100%; } } } }
    .modal-fluxio {
      &.isFeed {
        .modal-content {
          img {
            max-height: 400px;
            width: 100%;
            object-fit: cover;
            margin-bottom: 50px; } } } }
     :global {
      @media all and (max-width: 768px) {
        .modal-content {
          .container,
          .container-md,
          .container-sm,
          .modal-body {
            padding: 0; } } } } } }
