.navigation {
  &.menu-component {
    .main-menu-toggle {
      display: none; }
    .main-menu {
      .main-menu-container {
        flex-direction: row !important;
        justify-content: flex-start !important;
        max-height: 500px;
        transition: all 0.25s ease-in;
        >.row {
          width: 100%; } } }
     :global {
      @media all and (max-width: 768px) {
        .main-menu-toggle {
          display: inline-block;
          font-size: 26px;
          cursor: pointer; }
        .main-menu {
          height: auto !important;
          .main-menu-container {
            flex-direction: column !important;
            justify-content: flex-start !important;
            padding: 15px 0;
            &>* {
              margin-bottom: 10px; }
            &.hide {
              max-height: 0;
              padding: 0;
              overflow: hidden;
              transition: all 0.15s ease-out; } } } } } }
  &.menu-component_v2 {
     :global {
      @media all and (max-width: 768px) {
        .main-menu-toggle {
          display: block; } } } } }
