.tabs-container_v2 {
  .dropdown-active-item {
    display: none; }
  .nav-link {
    padding: 0; }
  @media all and (max-width: 768px) {
    .dropdown-active-item {
      display: block;
      position: relative;
       :global(.bob-icon) {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer; } }
    .nav-pills {
      flex-direction: column !important;
      transition: max-height 1s ease-in-out;
      transition-delay: 0ms;
      height: auto;
      max-height: 1000px;
      overflow: hidden;
      &.dropdown-close {
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        max-height: 0;
        &>.nav-item {
          visibility: hidden; } }
      &>.nav-item {
        width: 100%;
        &.active {
          display: none; } } } } }
