.cards {
  // cards flex fix
  // &.card-top,
  // &.social-card-share
  //   .card
  //     & > *
  //       flex-direction: column
   :global {
    .card {
      &>.container,
      &>.container-fluid {
        flex-direction: column; } } } }
