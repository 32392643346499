
.navigation {
  &.menu-toggle-component {
    .main-menu {
      .main-menu-container {
        z-index: 1050;
        flex-direction: row !important;
        transition: width 0.25s ease-in;
        position: fixed !important;
        width: 100%;
        right: 0;
        top: 0;
        overflow-y: auto;
        &>div {
          flex-direction: column !important; }
        &.hide {
          width: 0;
          padding: 0;
          overflow: hidden;
          transition: width 0.15s ease-out; } }
      .main-menu-item {
        width: auto;
        height: auto; }
      .main-menu-toggle {
        display: block;
        cursor: pointer; }
      .main-menu-close {
        height: auto;
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        cursor: pointer;
        &.hide {
          display: none; } } }
    @media only screen and (min-width: 992px) {
      .main-menu {
        .main-menu-container {
          width: 35%;
          max-width: 500px; } } } } }
